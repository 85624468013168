import React from "react";

type Props = {
  title?: string;
  info?: string;
};

const InfoDashScreen = ({ title = "Description de la page", info }: Props) => {
  return (
    <React.Fragment>
      <div className="flex p-5 flex-col gap-3 w-full bg-white rounded-[12px]">
        <span className="flex text-black font-ligth text-[16px] leading-none">
          {title}
        </span>
        <span className="flex text-black text-[14.5px]">{info}</span>
      </div>
    </React.Fragment>
  );
};

export default InfoDashScreen;
