import React from "react";
import FormMessage from "../../../../../../../components/form_components/message";
import TextAreaInputForm from "../../../../../../../components/form_components/textarea_input";
import TextInputForm from "../../../../../../../components/form_components/text_input";
import EmailInputForm from "../../../../../../../components/form_components/email_input";
import SelectInputForm from "../../../../../../../components/form_components/select_input";
import PhoneInputForm from "../../../../../../../components/form_components/phone_input";
import DateInputFrom from "../../../../../../../components/form_components/date_input";
import moment from "moment";
import { useBasicsStore } from "../../../../../../../configs/stores/basics";
import { isEmail, isPhoneNumber } from "../../../../../../../constants";
import SubmitForm from "../../../../../../../components/form_components/submit";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";

const DashUsersScreenAddMissionnaireScreen = () => {
  const [formKey, setFormKey] = React.useState(uuidv4());
  const [isLoading, setIsLoading] = React.useState(false);
  const [haveOtherChurch, setHaveOtherChurch] = React.useState(false);
  const [countryUpdateId, setCountryUpdateId] = React.useState<string>();
  const [departmentUpdateId, setDepartmentUpdateId] = React.useState<string>();
  const { churchs, countries, departments, registrationAspirant } =
    useBasicsStore();
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    lastname: null,
    firstname: null,
    country: null,
    chruch: null,
    otherchurch: null,
    department: null,
    phone: null,
    email: null,

    blood_group: null,
    birth_date: null,
    baptism_date: null,
    state: null,
    city: null,
    conversion_story: null,
    reason_join_mdvie: null,

    general: null,
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // lastname
    if (form.lastname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, lastname: true }));
    } else {
      setErrors((e) => ({ ...e, lastname: null }));
    } // firstname
    if (form.firstname.length < 2) {
      errorOccured = true;
      setErrors((e) => ({ ...e, firstname: true }));
    } else {
      setErrors((e) => ({ ...e, firstname: null }));
    } // country
    if (form.country.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, country: true }));
    } else {
      setErrors((e) => ({ ...e, country: null }));
    } // church
    if (form.church.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, church: true }));
    } else {
      setErrors((e) => ({ ...e, church: null }));
    } // otherchurch
    if (haveOtherChurch && form.otherchurch.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, otherchurch: true }));
    } else {
      setErrors((e) => ({ ...e, otherchurch: null }));
    } // department
    if (form.department.length === 0) {
      errorOccured = true;
      setErrors((e) => ({ ...e, department: true }));
    } else {
      setErrors((e) => ({ ...e, department: null }));
    } // phone
    if (form.phone.length === 0 || !isPhoneNumber(form.phone)) {
      errorOccured = true;
      let msg = "Contact téléphonique incorrecte";
      setErrors((e) => ({ ...e, phone: msg }));
    } else {
      setErrors((e) => ({ ...e, phone: null }));
    } // email
    if (form.email.length === 0 || !isEmail(form.email)) {
      errorOccured = true;
      let msg = "Email incorrecte";
      setErrors((e) => ({ ...e, email: msg }));
    } else {
      setErrors((e) => ({ ...e, email: null }));
    }
    return errorOccured;
  }
  function changeFormKey() {
    setFormKey(uuidv4());
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function getValue(t: any) {
    let value = JSON.parse(t)?.value ?? "none";
    return value !== "none" ? value : null;
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    const form = e.target.elements;
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: form.country.value,
      church: form.church.value,
      otherchurch: haveOtherChurch ? form.otherchurch.value : null,
      department: form.department.value,
      phone: form.phone.value,
      email: form.email.value,

      blood_group: form.blood_group.value,
      birth_date: moment(form.birth_date.value, "DD MMMM YYYY", "fr").toDate(),
      baptism_date: moment(
        form.baptism_date.value,
        "DD MMMM YYYY",
        "fr"
      ).toDate(),
      state: form.state.value,
      city: form.city.value,
      job: form.job.value,
      conversion_story: form.conversion_story.value,
      reason_join_mdvie: form.reason_join_mdvie.value,
    };
    if (checkForm(formValues)) {
      return;
    }
    setIsLoading(true);
    const formDatas = {
      lastname: form.lastname.value,
      firstname: form.firstname.value,
      country: getValue(form.country.value),
      church: !haveOtherChurch ? getValue(form.church.value) : null,
      otherchurch: haveOtherChurch ? form.otherchurch.value : "",
      department: getValue(form.department.value),
      phone: form.phone.value,
      email: form.email.value,

      blood_group: getValue(form.blood_group.value),
      birth_date: moment(form.birth_date.value, "DD MMMM YYYY", "fr").toDate(),
      baptism_date: moment(
        form.baptism_date.value,
        "DD MMMM YYYY",
        "fr"
      ).toDate(),
      state: form.state.value,
      city: form.city.value,
      job: form.job.value,
      conversion_story: form.conversion_story.value,
      reason_join_mdvie: form.reason_join_mdvie.value,
    };
    registrationAspirant({
      ...formDatas,
    }).then((res: any) => {
      if (res.success) {
        toast.success("Enregistrer avec success !");
        changeFormKey();
        // setUser(res.data);
      }
      if (!res.success) {
        setErrors((e) => ({
          ...e,
          general: res.message.toString(),
        }));
      }
      setIsLoading(false);
    });
  }
  const egliseCentre = churchs.filter((e) =>
    e.name.toLowerCase().startsWith("c")
  );
  const bloodgroups = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "O",
      value: "O",
    },
    {
      label: "AB",
      value: "AB",
    },
  ];
  return (
    <div className="flex w-full flex-row max-w-[60%] gap-[50px] bg-white rounded-[8px] p-5">
      <div className="flex flex-1 h-auto flex-col phone:pb-16">
        <div className="flex flex-col gap-y-4 w-[80%] min_pc_mini:w-[100%] h-auto phone:w-[100%]">
          <div className="flex min_pc_mini:hidden flex-1 justify-center pb-0"></div>
          <span className="flex text-[20px] font-bold select-none">
            Ajouter un nouveau membre
          </span>
          <form
            key={formKey}
            className="flex flex-col gap-4"
            onSubmit={!isLoading ? onSubmitForm : undefined}
            onChange={updateCheckForm}
          >
            <div className="flex flex-col min_input_row_to_column:flex-row min_input_row_to_column:gap-6 gap-4">
              <TextInputForm
                title={"Nom"}
                name={"lastname"}
                error={!!errors.lastname}
              />
              <TextInputForm
                title={"Prénoms"}
                name={"firstname"}
                error={!!errors.firstname}
              />
            </div>
            <div className="flex flex-col min_input_row_to_column:flex-row min_input_row_to_column:gap-6 gap-4">
              <SelectInputForm
                title={"Groupe Sanguin"}
                name={"blood_group"}
                error={!!errors.blood_group}
                options={bloodgroups}
                onChange={(_) => {
                  setErrors((e) => ({ ...e, blood_group: null }));
                }}
              />
              <DateInputFrom
                title={"Date de naissance"}
                name={"birth_date"}
                error={!!errors.birth_date}
                maxDate={moment()}
              />
            </div>
            <div className="flex flex-col min_input_row_to_column:flex-row min_input_row_to_column:gap-6 gap-4">
              <DateInputFrom
                title={"Date de baptème"}
                name={"baptism_date"}
                error={!!errors.baptism_date}
                maxDate={moment()}
              />
              <TextInputForm
                title={"Profession"}
                name={"job"}
                error={!!errors.job}
              />
            </div>
            <div className="flex flex-col gap-[6px]">
              <SelectInputForm
                title={"Département souhaité ?"}
                name={"department"}
                error={!!errors.department}
                options={departments.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(_) => {
                  setDepartmentUpdateId(_.value);
                  setErrors((e) => ({ ...e, department: null }));
                }}
              />
              {!!departments.find((e) => e.id === departmentUpdateId)
                ?.description ? (
                <span className="flex text-[15px] text-primary">
                  {
                    departments.find((e) => e.id === departmentUpdateId)
                      ?.description
                  }
                </span>
              ) : null}
            </div>
            <div className="flex flex-col min_input_row_to_column:flex-row min_input_row_to_column:gap-6 gap-4">
              <SelectInputForm
                title={"Pays"}
                name={"country"}
                error={!!errors.country}
                options={countries
                  .filter((e) => e.active)
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                onChange={(_) => {
                  setCountryUpdateId(_.value);
                  setErrors((e) => ({ ...e, country: null }));
                }}
              />
              <PhoneInputForm
                title={"Contact Téléphonique"}
                prefix={countries.find((e) => e.id === countryUpdateId)?.prefix}
                name={"phone"}
                error={!!errors.phone}
              />
            </div>
            <div className="flex flex-col min_input_row_to_column:flex-row min_input_row_to_column:gap-6 gap-4">
              <TextInputForm
                title={"Ville"}
                name={"state"}
                error={!!errors.state}
              />
              <TextInputForm
                title={"Commune"}
                name={"city"}
                error={!!errors.city}
              />
            </div>
            <SelectInputForm
              title={"Église"}
              name={"church"}
              error={!!errors.church}
              options={egliseCentre
                .concat(
                  churchs.filter(
                    (e) =>
                      !egliseCentre
                        .map((i) => i.id.toString())
                        .includes(e.id.toString())
                  )
                )
                .map((item) => ({
                  value: item.id,
                  label: item.name,
                }))
                .concat({
                  value: "otherchurch",
                  label: "Autre Église",
                })}
              onChange={(_) => {
                if (_) {
                  const v = _.value;
                  if (v === "otherchurch") {
                    setHaveOtherChurch(true);
                  } else {
                    if (haveOtherChurch) {
                      setHaveOtherChurch(false);
                    }
                  }
                }
                setErrors((e) => ({ ...e, church: null }));
              }}
            />
            {haveOtherChurch && (
              <TextInputForm
                key={"otherchurch"}
                title={"Préciser le nom de l'église"}
                name={"otherchurch"}
                error={!!errors.otherchurch}
              />
            )}
            <EmailInputForm title={"Email"} error={!!errors.email} />
            <TextAreaInputForm
              title={"Témoignage de conversion"}
              name={"conversion_story"}
              error={!!errors.conversion_story}
            />
            <TextAreaInputForm
              title={"Pourquoi intégrer MDVIE ?"}
              name={"reason_join_mdvie"}
              error={!!errors.reason_join_mdvie}
            />
            {errors.general && (
              <div className="mt-2">
                <FormMessage message={errors.general} styleMessage={"error"} />
              </div>
            )}
            <SubmitForm isLoading={isLoading} title={"Enregistrer"} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashUsersScreenAddMissionnaireScreen;
