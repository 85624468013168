import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { request } from "../../../../../../../configs/UrlBases";
import MissingPage from "../../../../../../missing";
import Loader from "../../../../../../loader";
import DashUsersScreenMissionnaireInfos from "./infos";

const DashUsersScreenMissionnaireScreen = () => {
  const { id: userId } = useParams();

  const {
    data: UserData,
    isLoading: isLoadingUserData,
    isRefetching: isRefetchingUserData,
    refetch,
  } = useQuery({
    queryKey: ["/user/infos" + userId],
    queryFn: () =>
      request({
        url: "/user/infos",
        params: {
          id: userId,
        },
      }),
    enabled: userId ? true : false,
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data.data?.data;
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userId) {
    return (
      <MissingPage message="Le formulaire de votre requète est incorrecte." />
    );
  }

  if (isLoadingUserData || isRefetchingUserData) {
    return <Loader />;
  }

  if (!UserData && !(isLoadingUserData || isRefetchingUserData)) {
    return <MissingPage message="Missionnaire introuvable" />;
  }

  return (
    <React.Fragment>
      <div className="flex flex-1 gap-5 flex-col h-full w-full">
        <DashUsersScreenMissionnaireInfos
          user={UserData}
          isLoading={isRefetchingUserData}
          refresh={refetch}
        />
      </div>
    </React.Fragment>
  );
};

export default DashUsersScreenMissionnaireScreen;
