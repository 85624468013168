import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../configs/stores/auth";
import { Plus } from "lucide-react";
// import MissionnaireNewModal from "../../../../../components/modals/models/missionnaire_new";
import { Outlet } from "react-router-dom";

const DashUsersScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const { isRefetchingUsers, refetchUsers } = useAdminDataStore();
  // const [isOpenNewUser, setisOpenNewUser] = React.useState(false);
  // function openCloseModal() {
  //   setisOpenNewUser(!isOpenNewUser);
  // }
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchUsers,
    queryKey: ["users"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Missionnaires">
          <ScreenHeaderActionItem
            title="Ajouter nouveau"
            icon={<Plus size={20} fill="black" />}
            // onClick={openCloseModal}
            url="./missionaries/add"
          />
          <ScreenHeaderActionRefresh
            onClick={refetchUsers}
            isLoading={isRefetchingUsers}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <Outlet />
        </ScreenContentLayout>
      </div>
      {/* <MissionnaireNewModal open={isOpenNewUser} handleClose={openCloseModal} /> */}
    </React.Fragment>
  );
};

export default DashUsersScreen;
