import React from "react";
import ScreenContentLayoutMenu from "../../../../../components/screen_content_layout/menu";
import { Outlet } from "react-router-dom";

const DashUsersScreenLayout = () => {
  const menus = [
    {
      title: "Missionnaires",
      url: "",
    },
    {
      title: "Aspirants",
      url: "aspirants",
    },
    {
      title: "Administrateurs",
      url: "admins",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 gap-1 flex-col h-full w-full">
        <ScreenContentLayoutMenu menus={menus} />
        <Outlet />
      </div>
    </React.Fragment>
  );
};

export default DashUsersScreenLayout;
