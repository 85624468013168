import React from "react";
import { useAuthStore } from "../../../../../configs/stores/auth";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import MissionnaireNewModal from "../../../../../components/modals/models/missionnaire_new";
import TableDataAutoList from "../../../../../components/table";
import UserActions from "./actions";
import InfoDashScreen from "./info";

const DashUsersScreenDefault = () => {
  const { isAuthenticated } = useAuthStore();
  const { users, refetchUsers } = useAdminDataStore();
  const [isOpenNewUser, setisOpenNewUser] = React.useState(false);
  function openCloseModal() {
    setisOpenNewUser(!isOpenNewUser);
  }
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchUsers,
    queryKey: ["users"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom & Prénoms",
      slug: "name",
    },
    {
      label: "Contact",
      slug: "phone",
    },
    {
      label: "Adresse email",
      slug: "email",
    },
    {
      label: "Pays",
      slug: "country",
    },
    {
      label: "Église",
      slug: "church",
    },
    {
      label: "Département",
      slug: "department",
    },
    {
      label: "Grade",
      slug: "grade",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 gap-5 flex-col h-full w-full">
        <InfoDashScreen info="Liste des utilisateurs enregistrés ayant réussi à passer les étapes pour devenir au moins missionnaire Messager." />
        <TableDataAutoList
          columns={columns}
          rows={users
            .map((e) => ({
              item: e,
              ...e,
              name: `${e.lastname ?? ""} ${e.firstname ?? ""}`.trim(),
              country: e?.country?.name ?? "",
              department: e?.department?.name ?? "",
              church:
                e?.church?.name ??
                (e?.otherchurch ? "Autre: " + e.otherchurch : null) ??
                "",
            }))
            .filter(
              (u) =>
                !["aspirant", "superadmin"].includes(
                  u.grade.toLocaleLowerCase()
                )
            )
            .sort((a, b) =>
              a.name
                .toLocaleLowerCase()
                .localeCompare(b.name.toLocaleLowerCase(), undefined, {
                  sensitivity: "base",
                })
            )}
          actions={UserActions}
        />
      </div>
      <MissionnaireNewModal open={isOpenNewUser} handleClose={openCloseModal} />
    </React.Fragment>
  );
};

export default DashUsersScreenDefault;
