import React from "react";
import ScreenHeader, { ScreenHeaderActionRefresh } from "../../../../components/screen_header";
import ScreenContentLayout from "../../../../components/screen_content_layout";
import { BookText } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import request from "../../../../configs/request";
import FormationItem from "./item";

const DashFormationsScreen = () => {
  const {
    data = [],
    refetch,
    fetchStatus,
  } = useQuery({
    queryKey: ["formations"],
    queryFn: () =>
      request({
        url: "formation/all",
        method: "get",
        params: {},
      }),
    select(response) {
      return (response?.data?.data ?? []) as Array<any>;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });
  return (
    <div className="flex flex-1 flex-col h-full w-full">
      <ScreenHeader
        icon={<BookText size={20} color="white" />}
        title="Mes Formations"
      >
        <ScreenHeaderActionRefresh
          onClick={refetch}
          isLoading={fetchStatus === "fetching"}
        />
      </ScreenHeader>
      <ScreenContentLayout>
        <div className="flex flex-col gap-6">
          <span className="flex text-[25px] text-black font-medium">
            Bienvenue sur votre espace de formations
          </span>
          <div className="flex flex-row flex-wrap w-full overflow-y-auto gap-4">
            {data &&
              data
                .map((item, i) => {
                  return <FormationItem key={item.id} item={item} />;
                })}
            {data?.filter((e) => e.status).length === 0 && (
              <span className="flex text-black/50 select-none">
                Aucune Formation enregistrer pour l'instant !
              </span>
            )}
          </div>
        </div>
      </ScreenContentLayout>
    </div>
  );
};
//?.filter((e) => e.status)

export default DashFormationsScreen;
