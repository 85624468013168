import React, { useEffect } from "react";
import ImageBg from "../../assets/backgroundImage.jpg";
import { useSearchParams } from "react-router-dom";
import { request } from "../../configs/UrlBases";
import { useMutation, useQuery } from "@tanstack/react-query";
import MissingPage from "../missing";
import Loader from "../loader";
import UpdateUserAccessForm from "./form";

const UpdateUserAccessPage = () => {
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");

  const {
    data: UserData,
    isLoading: isLoadingUserData,
    isRefetching: isRefetchingUserData,
    refetch,
  } = useQuery({
    queryKey: ["/user/" + userId],
    queryFn: () =>
      request({
        url: "/user/find-one",
        params: {
          id: userId,
        },
      }),
    enabled: userId ? true : false,
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data.data?.data;
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => {
      return request({
        url: "/user/update-access",
        data,
        params: {
          id: userId,
        },
        method: "post",
      });
    },
    onSuccess: (resData) => {
      setUpdateSuccess(true);
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userId) {
    return (
      <MissingPage message="Le formulaire de votre requète est incorrecte." />
    );
  }

  if (isLoadingUserData || isRefetchingUserData) {
    return <Loader />;
  }

  if (!UserData && !(isLoadingUserData || isRefetchingUserData)) {
    return <MissingPage message="Missionnaire introuvable" />;
  }

  if (UserData && !updateSuccess) {
    return (
      <UpdateUserAccessForm
        user={UserData}
        mutate={mutate}
        isLoading={isPending}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="flex w-full flex-row px-4 gap-[50px] min_pc_mini:px-[10px]">
        <div className="hidden min_pc_mini:flex flex-1 py-10 justify-center">
          <div className="flex w-full phone:w-[100%] rounded-[8px] max-h-[600px] items-center justify-center bg-[#e2e8f0]">
            <img
              src={ImageBg}
              alt="campagne"
              className="flex w-full h-full my-auto select-none mx-auto object-contain rounded-[8px]"
            />
          </div>
        </div>
        <div className="flex flex-1 h-auto flex-col min_pc_mini:py-10 py-10 phone:pt-5 phone:pb-16">
          <div className="flex flex-col min_pc_mini:pt-7 pb-20 gap-y-4 w-[80%] min_pc_mini:w-[100%] h-auto phone:w-[100%]">
            <div className="flex min_pc_mini:hidden flex-1 justify-center pb-5">
              <div className="flex w-[100%] rounded-[8px] max-h-[600px] items-center justify-center bg-black">
                <img
                  src={ImageBg}
                  alt="campagne"
                  className="flex w-full h-full my-auto select-none mx-auto object-contain rounded-[8px]"
                />
              </div>
            </div>
            <span className="flex text-[22px] font-bold text-green-500">
              Félicitation {UserData.firstname + " " + UserData.lastname}, Mise
              à jour réussie !
            </span>
            <span className="flex text-[18px] font-semibold">
              Votre enregistrement a été effectué avec succès et pris en compte.
            </span>
            <a
              href="/"
              className="mt-6 px-4 py-2 w-fit bg-primary text-white rounded-lg shadow hover:bg-hover active:bg-active"
            >
              {"< "} Retour à l'accueil
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateUserAccessPage;
