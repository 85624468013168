import clsx from "clsx";
import {
  ExternalLink,
  KeyRound,
  LockKeyhole,
  Trash2,
  UserPen,
  MailWarning,
} from "lucide-react";
import React from "react";
import MissionaryUpdateModal from "../../../../../components/modals/models/missionary_update";
import MissionaryUpdateMDPModal from "../../../../../components/modals/models/missionary_update_password";
import MissionaryUpdateAccessModal from "../../../../../components/modals/models/missionary_update_access";
import ConfirmActionModal from "../../../../../components/modals/models/confirmation";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useNavigate } from "react-router-dom";
import MissionarySendAccessLinkModal from "../../../../../components/modals/models/missionary_send_access_link";

type Props = {
  row?: any;
};

const UserActions = ({ row }: Props) => {
  const navigate = useNavigate();
  const { removeUser } = useAdminDataStore();
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  const [isOpenModifyMDP, setisOpenModifyMDP] = React.useState(false);
  function closeModifyMDP() {
    setisOpenModifyMDP(false);
  }
  const [isOpenSendAccessLinkModal, setisOpenSendAccessLinkModal] =
    React.useState(false);
  function closeSendAccessLinkModal() {
    setisOpenSendAccessLinkModal(false);
  }
  const [isOpenModifyAccess, setisOpenModifyAccess] = React.useState(false);
  function closeModifyAccess() {
    setisOpenModifyAccess(false);
  }
  const [isOpen, setisOpen] = React.useState(false);
  function close() {
    setisOpen(false);
  }
  async function confirm() {
    if (!row?.item.id) {
      alert("Requète non valide !");
      return;
    }
    return await removeUser(row?.item.id).then((res) => {
      if (!res.success) {
        !res?.message && alert(res?.message);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col" key={row?.item.id}>
        <div
          onClick={() => {
            if (row?.item.id) {
              navigate("/dashboard/missionaries/" + row?.item.id);
            } else {
              alert("Recharger la page !");
            }
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <ExternalLink size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Voir plus
          </span>
        </div>
        <div
          onClick={() => {
            setisOpenModify(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <UserPen size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Modifier utilisateur
          </span>
        </div>
        <div
          onClick={() => {
            setisOpenModifyMDP(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <KeyRound size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Réinitialiser mot de passe
          </span>
        </div>
        <div
          onClick={() => {
            setisOpenSendAccessLinkModal(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <MailWarning size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Sms de Réinitialisation
          </span>
        </div>
        <div
          onClick={() => {
            setisOpenModifyAccess(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <LockKeyhole size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Modifier les accés
          </span>
        </div>
        <div
          onClick={() => {
            setisOpen(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 text-red-500 active:bg-slate-500/40 "
          )}
        >
          <Trash2 size={18} />
          <span className="flex  font-medium text-[15px]">
            Supprimer l'utilisateur
          </span>
        </div>
      </div>
      <MissionaryUpdateModal
        open={isOpenModify}
        item={row.item}
        handleClose={closeModify}
      />
      <MissionaryUpdateMDPModal
        open={isOpenModifyMDP}
        item={row.item}
        handleClose={closeModifyMDP}
      />
      <MissionarySendAccessLinkModal
        open={isOpenSendAccessLinkModal}
        item={row.item}
        handleClose={closeSendAccessLinkModal}
      />
      <MissionaryUpdateAccessModal
        open={isOpenModifyAccess}
        item={row.item}
        handleClose={closeModifyAccess}
      />
      <ConfirmActionModal
        open={isOpen}
        message="Voulez-vous vraiment supprimer cet utilisateur ?"
        confirm={confirm}
        handleClose={close}
      />
    </React.Fragment>
  );
};

export default UserActions;
