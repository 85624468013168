import { ChevronDown } from "lucide-react";
import { primaryColor } from "../../../../../constants";

type Props = {
  item?: any;
  state?: "now" | "disponible" | "next";
};

const FormationItem = ({ item, state = "now" }: Props) => {
  const validateURL = (url: string) => {
    return /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w.@?^=%&:/~+#-]*)?$/i.test(
      url
    );
  };
  return (
    <div className="flex p-5 flex-col gap-3 min-w-[300px] max-w-[400px] justify-between bg-white rounded-[10px] border-black/10">
      <span className=" font-semibold text-[22px] leading-tight line-clamp-1">
        {item.title}
      </span>
      <div className="flex items-center justify-center h-[200px] aspect-video bg-black rounded-[8px] relative">
        {item.url ? (
          validateURL(item.url) ? (
            <iframe
              className="flex h-full w-full"
              src={item.url}
              title="Video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          ) : (
            <div className="flex text-red-500">
              <span>Lien invalide</span>
            </div>
          )
        ) : null}
      </div>
      <span className=" text-[15px] h-[70px] leading-tight line-clamp-4">
        {item.description}
      </span>
      <div className="flex transition-all duration-150 rounded-[8px] select-none bg-primary/25 w-full py-2 gap-2 hover:bg-hover/40 active:bg-active/50 cursor-pointer items-center justify-center">
        <ChevronDown color={primaryColor} size={20} />
        <span className="flex text-[16px] text-primary font-semibold">
          Plus d'options
        </span>
        <span className="flex text-[16px] text-primary font-normal">
          {item.status === false ? "(Désactiver)" : ""}
        </span>
      </div>
    </div>
  );
};

export default FormationItem;
