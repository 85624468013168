import React from "react";
import ModalCustom from "../..";
import { useAdminDataStore } from "../../../../configs/stores/admindataStore";
import clsx from "clsx";
import { ToastErrorNotifier } from "../../../../constants";

type Props = {
  item: any;
  handleClose: () => void;
  open: boolean;
};

const MissionarySendAccessLinkModal = ({
  item,
  open = false,
  handleClose = () => {},
}: Props) => {
  const { sendUserReinitAccessLink } = useAdminDataStore();
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  function onSubmitForm() {
    if (!item?.country && !item.phone.startsWith("+")) {
      ToastErrorNotifier({ message: "Le numero de téléphone incorrecte" });
      return;
    }
    setisLoading(true);
    sendUserReinitAccessLink(item.id).then((res: any) => {
      setisLoading(false);
      if (res.success) {
        handleClose();
      } else {
      }
    });
  }
  function normalisePhoneNumber() {
    if (!item?.country && !item.phone.startsWith("+")) {
      return item.phone;
    }
    return item.phone.startsWith(item.country.prefix)
      ? item.phone
      : item.country.prefix + item.phone;
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[450px] p-3 pt-0 gap-y-3 items-start justify-center">
        <span className="flex text-[20px] font-medium">
          Envoyer un sms de Réinitialisation
        </span>
        <span className="flex text-[15px] font-medium">
          Une fois le SMS de réinitialisation envoyé au numéro suivant :{" "}
          {normalisePhoneNumber()}, l'utilisateur recevra un lien lui permettant
          de modifier son adresse e-mail et son mot de passe sur un espace
          dédié.
        </span>
        <span className="flex text-[15px] font-medium text-red-500">
          ** Veuillez vous assurer que le numéro est correct avant d'envoyer le
          SMS de réinitialisation.
        </span>
        <button
          disabled={isLoading}
          onClick={!isLoading ? onSubmitForm : undefined}
          className={clsx(
            "flex justify-center items-center mt-2 w-fit px-10 rounded-[8px] h-[47px] transition-all duration-150",
            !isLoading
              ? " bg-[#009ADE] hover:bg-hover active:bg-active"
              : "bg-buttonInactiveColor"
          )}
        >
          <span
            className={clsx(
              "flex select-none",
              !isLoading
                ? "text-[16px] font-bold text-white "
                : "text-[15px] font-semibold text-white/80"
            )}
          >
            {!isLoading ? "Envoyer le lien" : "Patienter un instant..."}
          </span>
        </button>
      </div>
    </ModalCustom>
  );
};

export default MissionarySendAccessLinkModal;
