import axios from "axios";

export const onlineAccountsApisUrl = "https://apimissionnaire.messagesdevie.fr";
export const localAccountsApisUrl = window.location.hostname.includes("local")
  ? "http://localhost:4501"
  : window.location.hostname.includes("192.168")
  ? "http://" + window.location.hostname + ":4501"
  : onlineAccountsApisUrl;

export const usedBaseUrl = localAccountsApisUrl;

export function getCookie(cname: string) {
  let name = cname + "=";
  if (document?.cookie) {
    let decodedCookie = decodeURIComponent(document?.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
}

const client = axios.create({
  //   baseURL: process.env.API_PROD_URL,
  baseURL: usedBaseUrl+ "/v1/missionary",
  headers: {
    Accept: "application/json",
  },
});

const request = async ({ ...options }, router: any = null) => {
  client.defaults.headers.common.Authorization = `Bearer ${getCookie("uat")}`;
  const onSuccess = (response: any) => response;
  const onError = (error: any) => {
    if (error?.response?.status === 403) {
      router && router.push("/403");
    }
    return error;
  };
  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export default request;
