import "./loader.css";

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="flex flex-col items-center gap-7">
        <div className="loader"></div>
        <h3>Chargement</h3>
      </div>
    </div>
  );
};

export default Loader;
