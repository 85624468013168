import React from "react";
import ScreenHeader, {
  ScreenHeaderActionItem,
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import { useQuery } from "@tanstack/react-query";
import request from "../../../../../configs/request";
import { Plus } from "lucide-react";
import FormationItem from "./item";

const DashManageFormationsScreenDefault = () => {
  const {
    data = [],
    refetch,
    fetchStatus,
  } = useQuery({
    queryKey: ["admins", "formations"],
    queryFn: () =>
      request({
        url: "admin/formation/all",
        method: "get",
        params: {},
      }),
    select(response) {
      return (response?.data?.data ?? []) as Array<any>;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Gérer les formations">
          <ScreenHeaderActionItem
            title="Ajouter"
            icon={<Plus size={20} fill="black" />}
            url="add"
          />
          <ScreenHeaderActionRefresh
            onClick={refetch}
            isLoading={fetchStatus === "fetching"}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <div className="flex flex-row flex-wrap w-full overflow-y-auto gap-4">
            {data &&
              data?.map((item, i) => {
                return <FormationItem key={item.id} item={item} />;
              })}
            {data?.length === 0 && (
              <span className="flex text-black/50 select-none">
                Aucune Formation enregistrer pour l'instant !
              </span>
            )}
          </div>
        </ScreenContentLayout>
      </div>
    </React.Fragment>
  );
};

export default DashManageFormationsScreenDefault;
