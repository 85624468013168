import React from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import HomePage from "./pages/home";
import { Forloadandnavigate } from "./functions/useNaviLoadBoth";
import RegistrationPage from "./pages/registration";
import LoginPage from "./pages/login";
import DashboardPage from "./pages/dashboard";
import { Toaster } from "sonner";
import ContentLayout from "./layout";
import DashWelcomeScreen from "./pages/dashboard/screens/welcome";
import DashProfilScreen from "./pages/dashboard/screens/profil";
import DashMyMissionsScreen from "./pages/dashboard/screens/mymissions";
import DashSettingsScreen from "./pages/dashboard/screens/settings";
import MissingPage from "./pages/missing";
import DashDepartmentsScreen from "./pages/dashboard/screens/admins/departments";
import DashCountriesScreen from "./pages/dashboard/screens/admins/countries";
import DashChurchsScreen from "./pages/dashboard/screens/admins/churchs";
import DashUsersScreen from "./pages/dashboard/screens/admins/users";
import DashMissionsScreen from "./pages/dashboard/screens/admins/missions";
import DashMdvieOfficeScreen from "./pages/dashboard/screens/admins/mdvieoffices";
import DashDonScreen from "./pages/dashboard/screens/don";
import MissionsScreenMission from "./pages/dashboard/screens/admins/missions/mission";
import DashMissionsListScreen from "./pages/dashboard/screens/admins/missions/default";
import DashWalletScreen from "./pages/dashboard/screens/wallet";
import MissionWelcomeScreen from "./pages/dashboard/screens/admins/missions/mission/screens/welcome";
import MissionMissionariesScreen from "./pages/dashboard/screens/admins/missions/mission/screens/missionaries";
import InfoMissionaryPage from "./pages/info/missionary";
import DashInterviewsScreen from "./pages/dashboard/screens/admins/interviews";
import DashNotificationsScreen from "./pages/dashboard/screens/admins/notifications";
import DashNotificationsScreenDefault from "./pages/dashboard/screens/admins/notifications/default";
import DashNotificationsScreenEmail from "./pages/dashboard/screens/admins/notifications/screens/email";
import DashNotificationsScreenSms from "./pages/dashboard/screens/admins/notifications/screens/sms";
import DashInterviewsScreenDefault from "./pages/dashboard/screens/admins/interviews/default";
import DashInterviewsScreenSurveys from "./pages/dashboard/screens/admins/interviews/screens/surveys";
import InterviewsScreenInterview from "./pages/dashboard/screens/admins/interviews/screens/interview";
import DashInterviewsScreenSurveysDefault from "./pages/dashboard/screens/admins/interviews/screens/surveys/default";
import SurveyScreenSurvey from "./pages/dashboard/screens/admins/interviews/screens/surveys/survey";
import DashInterviewsScreenSub from "./pages/dashboard/screens/admins/interviews/sub_index";
import DashStatsScreen from "./pages/dashboard/screens/admins/stats";
import InterviewScreenDate from "./pages/dashboard/screens/admins/interviews/screens/interview/screens/date";
import InterviewsScreenInterviewDefault from "./pages/dashboard/screens/admins/interviews/screens/interview/default";
import DashProfilScreenDefault from "./pages/dashboard/screens/profil/default";
import DashProfilScreenUpdatePassword from "./pages/dashboard/screens/profil/screens/update_password";
import DashMyMissionsScreenDefault from "./pages/dashboard/screens/mymissions/default";
import DashMyMissionsScreenParticipations from "./pages/dashboard/screens/mymissions/screens/participations";
import DashMyMissionsScreenIntervews from "./pages/dashboard/screens/mymissions/screens/interviews";
import DashboardAdminPage from "./pages/dashboard/screens/admins";
import SmsScreenNewSms from "./pages/dashboard/screens/admins/notifications/screens/sms/screens/new";
import SmsScreenSms from "./pages/dashboard/screens/admins/notifications/screens/sms/screens/mail";
import EmailScreenMail from "./pages/dashboard/screens/admins/notifications/screens/email/screens/mail";
import EmailScreenNewMail from "./pages/dashboard/screens/admins/notifications/screens/email/screens/new";
import DashNotificationsScreenEmailDefault from "./pages/dashboard/screens/admins/notifications/screens/email/default";
import DashNotificationsScreenSmsDefault from "./pages/dashboard/screens/admins/notifications/screens/sms/default";
import RouteRequireAuth from "./middlewares/require_auth";
import DashMyMissionsScreenMission from "./pages/dashboard/screens/mymissions/screens/mission";
import DashMyMissionsScreenLayoutWithMenu from "./pages/dashboard/screens/mymissions/layout_with_menu";
import InterviewDateParticipantsPanel from "./pages/dashboard/screens/admins/interviews/screens/interview/screens/date/participants";
import InterviewDateMissionariesPanel from "./pages/dashboard/screens/admins/interviews/screens/interview/screens/date/missionaries";
import ParticipationPage from "./pages/participation";
import DevenirMissionnairePage from "./pages/devenirmissionnaire";
import RegistrationParticipantPage from "./pages/participer";
import ForgotPasswordPage from "./pages/forgotpassword";
import ForgotPasswordReinitPage from "./pages/forgotpasswordReinit";
import DashUsersScreenDefault from "./pages/dashboard/screens/admins/users/default";
import DashUsersScreenAspirantScreen from "./pages/dashboard/screens/admins/users/screens/aspirants";
import DashUsersScreenAdminScreen from "./pages/dashboard/screens/admins/users/screens/admins";
import DashUsersScreenMissionnaireScreen from "./pages/dashboard/screens/admins/users/screens/missionaire";
import DashUsersScreenLayout from "./pages/dashboard/screens/admins/users/layout";
import DashFormationsScreen from "./pages/dashboard/screens/formations";
import UpdateUserAccessPage from "./pages/update-access";
import DashUsersScreenAddMissionnaireScreen from "./pages/dashboard/screens/admins/users/screens/add";
import DashManageFormationsScreen from "./pages/dashboard/screens/admins/formations";
import DashManageFormationsScreenDefault from "./pages/dashboard/screens/admins/formations/default";
import DashManageFormationsScreenAdd from "./pages/dashboard/screens/admins/formations/screens/add";

function App() {
  return (
    <div className="flex flex-row flex-1 h-screen font-inter">
      <Routes>
        <Route path="/" element={<ContentLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword">
            <Route path="" element={<ForgotPasswordPage />} />
            <Route path=":id" element={<ForgotPasswordReinitPage />} />
          </Route>
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/participer" element={<RegistrationParticipantPage />} />
          <Route path="/participation" element={<ParticipationPage />} />
          <Route path="/update-access" element={<UpdateUserAccessPage />} />
          <Route
            path="/devenirmissionnaire"
            element={<DevenirMissionnairePage />}
          />
          <Route path="/info">
            <Route path="" element={<Navigate to={"/login"} />} />
            <Route path="missionary">
              <Route path="" element={<InfoMissionaryPage />} />
            </Route>
          </Route>
          <Route
            path="/dashboard"
            element={
              <RouteRequireAuth>
                <DashboardPage />
              </RouteRequireAuth>
            }
          >
            <Route path="" element={<DashWelcomeScreen />} />
            <Route path="mymissions" element={<DashMyMissionsScreen />}>
              <Route path=":id" element={<DashMyMissionsScreenMission />} />
              <Route element={<DashMyMissionsScreenLayoutWithMenu />}>
                <Route path="" element={<DashMyMissionsScreenDefault />} />
                <Route
                  path="interviews"
                  element={<DashMyMissionsScreenIntervews />}
                />
                <Route
                  path="participations"
                  element={<DashMyMissionsScreenParticipations />}
                />
              </Route>
            </Route>
            <Route path="profil" element={<DashProfilScreen />}>
              <Route path="" element={<DashProfilScreenDefault />} />
              <Route
                path="updatePassword"
                element={<DashProfilScreenUpdatePassword />}
              />
            </Route>
            <Route path="don" element={<DashDonScreen />} />
            <Route path="formations" element={<DashFormationsScreen />} />
            <Route path="wallet" element={<DashWalletScreen />} />
            <Route path="settings" element={<DashSettingsScreen />} />
            <Route element={<DashboardAdminPage />}>
              <Route path="stats" element={<DashStatsScreen />} />
              <Route path="countries" element={<DashCountriesScreen />} />
              <Route path="churchs" element={<DashChurchsScreen />} />
              <Route
                path="manage-formations"
                element={<DashManageFormationsScreen />}
              >
                <Route
                  path=""
                  element={<DashManageFormationsScreenDefault />}
                />
                <Route
                  path="add"
                  element={<DashManageFormationsScreenAdd />}
                />
              </Route>
              <Route path="departments" element={<DashDepartmentsScreen />} />
              <Route path="missionaries" element={<DashUsersScreen />}>
                <Route element={<DashUsersScreenLayout />}>
                  <Route path="" element={<DashUsersScreenDefault />} />
                  <Route
                    path="aspirants"
                    element={<DashUsersScreenAspirantScreen />}
                  />
                  <Route
                    path="admins"
                    element={<DashUsersScreenAdminScreen />}
                  />
                </Route>
                <Route
                  path="add"
                  element={<DashUsersScreenAddMissionnaireScreen />}
                />
                <Route
                  path=":id"
                  element={<DashUsersScreenMissionnaireScreen />}
                />
              </Route>
              <Route path="notifications" element={<DashNotificationsScreen />}>
                <Route path="" element={<DashNotificationsScreenDefault />} />
                <Route path="sms" element={<DashNotificationsScreenSms />}>
                  <Route
                    path=""
                    element={<DashNotificationsScreenSmsDefault />}
                  />
                  <Route path=":id" element={<SmsScreenSms />} />
                  <Route path="new" element={<SmsScreenNewSms />} />
                </Route>
                <Route path="email" element={<DashNotificationsScreenEmail />}>
                  <Route
                    path=""
                    element={<DashNotificationsScreenEmailDefault />}
                  />
                  <Route path=":id" element={<EmailScreenMail />} />
                  <Route path="new" element={<EmailScreenNewMail />} />
                </Route>
              </Route>
              <Route path="interviews" element={<DashInterviewsScreen />}>
                <Route path=":id" element={<InterviewsScreenInterview />}>
                  <Route
                    path=""
                    element={<InterviewsScreenInterviewDefault />}
                  />
                  <Route path=":date_id" element={<InterviewScreenDate />}>
                    <Route
                      path=""
                      element={<InterviewDateMissionariesPanel />}
                    />
                    <Route
                      path="participants"
                      element={<InterviewDateParticipantsPanel />}
                    />
                  </Route>
                </Route>
                <Route element={<DashInterviewsScreenSub />}>
                  <Route path="" element={<DashInterviewsScreenDefault />} />
                  <Route
                    path="surveys"
                    element={<DashInterviewsScreenSurveys />}
                  >
                    <Route
                      path=""
                      element={<DashInterviewsScreenSurveysDefault />}
                    />
                    <Route path=":id" element={<SurveyScreenSurvey />} />
                  </Route>
                </Route>
              </Route>
              <Route path="missions" element={<DashMissionsScreen />}>
                <Route path="" element={<DashMissionsListScreen />} />
                <Route path=":id" element={<MissionsScreenMission />}>
                  <Route path="" element={<MissionWelcomeScreen />} />
                  <Route
                    path="missionaries"
                    element={<MissionMissionariesScreen />}
                  />
                </Route>
              </Route>
              <Route path="mdvieoffices" element={<DashMdvieOfficeScreen />} />
            </Route>
          </Route>
          <Route path="*" element={<MissingPage />} />
        </Route>
      </Routes>
      <Header />
      <Forloadandnavigate />
      <Toaster richColors={true} expand={true} theme={"system"} />
    </div>
  );
}

export default App;
