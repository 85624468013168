import React from "react";
import { Outlet } from "react-router-dom";

const DashManageFormationsScreen = () => {
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <Outlet />
      </div>
    </React.Fragment>
  );
};

export default DashManageFormationsScreen;
