type Props = {
  item?: any;
  state?: "now" | "disponible" | "next";
};

const FormationItem = ({ item, state = "now" }: Props) => {
  const validateURL = (url: string) => {
    return /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w.@?^=%&:/~+#-]*)?$/i.test(
      url
    );
  };
  return (
    <div className="flex p-5 flex-col gap-3 min-w-[300px] max-w-[400px] justify-between bg-white rounded-[10px] border-black/10">
      <span className="flex font-semibold text-[22px] leading-tight">
        {item.title}
      </span>
      <div className="flex items-center justify-center h-[200px] aspect-video bg-black rounded-[8px] relative">
        {item.url ? (
          validateURL(item.url) ? (
            <iframe
              className="flex h-full w-full"
              src={item.url}
              title="Video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          ) : (
            <div className="flex text-red-500">
              <span>Lien invalide</span>
            </div>
          )
        ) : null}
      </div>
      <span className="flex text-[18px] leading-tight">
        {item.description}
      </span>
    </div>
  );
};

export default FormationItem;
