import React from "react";
import MissionnaireNewModal from "../../../../../../../components/modals/models/missionnaire_new";
import InfoDashScreen from "../../info";
import { ArrowLeft, CircleUserRound } from "lucide-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { ScreenHeaderActionRefresh } from "../../../../../../../components/screen_header";

type Props = {
  user: any;
  isLoading?: boolean;
  refresh: any;
};

const DashUsersScreenMissionnaireInfos = ({
  user,
  isLoading = false,
  refresh,
}: Props) => {
  const [isOpenNewUser, setisOpenNewUser] = React.useState(false);
  function openCloseModal() {
    setisOpenNewUser(!isOpenNewUser);
  }
  return (
    <React.Fragment>
      <MissionnaireNewModal open={isOpenNewUser} handleClose={openCloseModal} />
      <div className="flex flex-1 gap-5 flex-col h-full w-full">
        <InfoDashScreen info="Page d'information relatif a un missionnaire" />
        <div className="flex flex-row items-center gap-4">
          <Link
            to={"../../missionaries"}
            className="flex flex-row select-none cursor-pointer gap-3 items-center p-3 px-[16px] w-fit rounded-[12px] bg-white hover:bg-white/90"
          >
            <ArrowLeft />
            <span className="flex text-[17px]">Retour en arrière</span>
          </Link>
          <ScreenHeaderActionRefresh
            onClick={() => {
              return refresh ? refresh() : null;
            }}
            isLoading={isLoading}
          />
        </div>

        <div className="flex flex-col gap-y-4 w-fit p-5 min_tablet_mini:min-w-[40%] rounded-[10px] bg-white h-auto phone:w-[100%]">
          <div className="flex flex-row ">
            <div className="flex flex-col w-[250px] h-[200px] items-center justify-center rounded-[15px] p-5 bg-slate-200">
              <CircleUserRound size={50} />
            </div>
          </div>
          <MissionnaireField title="Nom" value={user.lastname} />
          <MissionnaireField title="Prenom" value={user.firstname} />
          <MissionnaireField
            title="Numero de téléphone"
            value={user.country.prefix + " " + user.phone}
          />
          <MissionnaireField title="Adresse Email" value={user.email} />
          <MissionnaireField title="Pays" value={user.country.name} />
          <MissionnaireField title="Grade" value={user.grade} />
          <MissionnaireField title="Département" value={user.department.name} />
          <MissionnaireField
            title="Église"
            value={user?.church ? user?.church.name : user?.otherchurch ?? ""}
          />
          <MissionnaireField
            title="Profession"
            value={user?.job || "Non défini"}
          />
          <MissionnaireField
            title="Ville"
            value={user?.state || "Non défini"}
          />
          <MissionnaireField
            title="Commune"
            value={user?.city || "Non défini"}
          />
          <MissionnaireField
            title="Date de Baptême"
            value={user?.baptism_date || "Non défini"}
          />
          <MissionnaireField
            title="Groupe sanguin"
            value={user?.blood_group || "Non défini"}
          />
          <MissionnaireField
            title="Date de naissance"
            value={user?.birth_date || "Non défini"}
          />
          <MissionnaireField
            title="Témoignage de conversion"
            value={user?.conversion_story || "Non défini"}
          />
          <MissionnaireField
            title="Pourquoi intégrer MDVIE ?"
            value={user?.reason_join_mdvie || "Non défini"}
          />
          <MissionnaireField
            title="Date d'inscription"
            value={moment(user.createdAt).format("LL")}
          />
        </div>
      </div>
      <Link
        to={"../../missionaries"}
        className="flex flex-row select-none cursor-pointer gap-3 items-center p-3 px-[16px] w-fit rounded-[12px] bg-white hover:bg-white/90"
      >
        <ArrowLeft />
        <span className="flex text-[17px]">Retour en arrière</span>
      </Link>
    </React.Fragment>
  );
};

type MissionnaireFieldProps = {
  title?: string;
  value?: string;
};
export const MissionnaireField = ({
  title = "",
  value = "",
}: MissionnaireFieldProps) => {
  return (
    <div className="flex flex-col gap-0">
      <span className="flex text-[14px]">{title}</span>
      <span className="flex text-[18px] font-semibold">{value}</span>
    </div>
  );
};

export default DashUsersScreenMissionnaireInfos;
