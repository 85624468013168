import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  addInterview,
  addInterviewDate,
  addLocality,
  addLocalityTeamMember,
  addMissionary,
  addSurvey,
  addSurveyQuestion,
  addUser,
  getAllData,
  getAllInterview,
  getAllSurvey,
  getInterview,
  getInterviewDate,
  getLocalities,
  getLocality,
  getLocalityTeamMember,
  getLocalityTeamMembers,
  getMission,
  getMissionaries,
  getMissionary,
  getMissionStats,
  getSurvey,
  removeDepartment,
  removeInterview,
  removeInterviewDate,
  removeLocality,
  removeLocalityTeamMember,
  removeMission,
  removeMissionary,
  removeSurvey,
  removeSurveyQuestion,
  removeUser,
  saveUserReinitAccess,
  sendUserReinitAccessLink,
  setParticipantInterviewDateValidation,
  setUserInterviewDateValidation,
  updateChurch,
  updateCountry,
  updateDepartment,
  updateInterview,
  updateInterviewDate,
  updateLocality,
  updateLocalityTeamMember,
  updateMdvieOffice,
  updateMission,
  updateMissionary,
  updateSurvey,
  updateSurveyQuestion,
  updateUser,
  updateUserReinitPassword,
} from "./functions/admindataStoreFunctions";
import { DepartmentModel } from "../../models/models";
import {
  addChurch,
  addCountry,
  addDepartment,
  addMdvieOffice,
  addMission,
  addMissionType,
  getAllChurch,
  getAllCountry,
  getAllDepartment,
  getAllMdvieOffice,
  getAllMission,
  getAllMissionType,
  getAllStat,
  getAllUser,
} from "./functions/admindataStoreFunctions";

const LOCAL_DATASTORE_NAME = "missionary-admin-datas";

export const UserRole = {
  USER: "USER",
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN",
};

export const InterviewType = {
  INTEGRATION: "INTÉGRATION",
  PARTICIPATION: "PARTICIPATION",
};

export const InterviewMeetType = {
  ONLINE: "En ligne",
  FACETOFACE: "En présentiel",
};

export const UserGrade = {
  // PARTICIPANT: "PARTICIPANT",
  ASPIRANT: "ASPIRANT",
  MESSAGER: "MESSAGER",
  MISSIONNAIRE: "MISSIONNAIRE",
  ASSISTANT_EVANGELISTE: "ASSISTANT_EVANGELISTE",
  EVANGELISTE: "EVANGELISTE",
  RESPONSABLE_DEPARTEMENT: "RESPONSABLE_DEPARTEMENT",
  SUPERADMIN: "SUPERADMIN",
};

type ContextProps = {
  isLoadingAll: boolean;
  getAll: () => Promise<any>;
  isRefetchingStats: boolean;
  stats: any;
  refetchStats: () => Promise<any>;
  isRefetchingDepartments: boolean;
  departments: Array<any>;
  addDepartment: (form: any) => Promise<any>;
  refetchDepartments: () => Promise<any>;
  updateDepartment: (id: string, form: any) => Promise<any>;
  removeDepartment: (id: string) => Promise<any>;
  isRefetchingUsers: boolean;
  users: Array<any>;
  refetchUsers: () => Promise<any>;
  updateUser: (id: string, form: any) => Promise<any>;
  updateUserReinitPassword: (id: string) => Promise<any>;
  sendUserReinitAccessLink: (id: string) => Promise<any>;
  addUser: (data: any) => Promise<any>;
  removeUser: (id: string) => Promise<any>;
  isRefetchingCountries: boolean;
  countries: Array<any>;
  addCountry: (name: string) => Promise<any>;
  refetchCountries: () => Promise<any>;
  updateCountry: (id: string, form: any) => Promise<any>;
  isRefetchingChurchs: boolean;
  churchs: Array<any>;
  addChurch: (form: any) => Promise<any>;
  refetchChurchs: () => Promise<any>;
  updateChurch: (id: string, form: any) => Promise<any>;
  isRefetchingMissions: boolean;
  missions: Array<any>;
  addMission: (form: any) => Promise<any>;
  removeMission: (id: string) => Promise<any>;
  getMission: (id: string) => Promise<any>;
  getMissionStats: (id: string) => Promise<any>;
  updateMission: (id: string, form: any) => Promise<any>;
  refetchMissions: () => Promise<any>;
  addLocality: (data: any) => Promise<any>;
  refetchLocalities: (missionId: string) => Promise<any>;
  getLocality: (id: string) => Promise<any>;
  updateLocality: (id: string, form: any) => Promise<any>;
  removeLocality: (id: string) => Promise<any>;
  addLocalityTeamMember: (data: any) => Promise<any>;
  refetchLocalityTeamMembers: (localityId: string) => Promise<any>;
  getLocalityTeamMember: (id: string) => Promise<any>;
  updateLocalityTeamMember: (id: string, form: any) => Promise<any>;
  removeLocalityTeamMember: (id: string) => Promise<any>;
  addMissionary: (data: any) => Promise<any>;
  refetchMissionaries: (missionId: string) => Promise<any>;
  getMissionary: (id: string) => Promise<any>;
  updateMissionary: (id: string, form: any) => Promise<any>;
  removeMissionary: (id: string) => Promise<any>;
  isRefetchingMdvieOffices: boolean;
  mdvieOffices: Array<any>;
  addMdvieOffice: (data: any) => Promise<any>;
  refetchMdvieOffices: () => Promise<any>;
  updateMdvieOffice: (id: string, form: any) => Promise<any>;
  isRefetchingMissionTypes: boolean;
  missionTypes: Array<any>;
  addMissionType: (data: any) => Promise<any>;
  refetchMissionTypes: () => Promise<any>;
  isRefetchingInterviews: boolean;
  interviews: Array<any>;
  addInterview: (data: any) => Promise<any>;
  getInterview: (id: string) => Promise<any>;
  getInterviewDate: (id: string) => Promise<any>;
  refetchInterviews: () => Promise<any>;
  updateInterview: (id: string, form: any) => Promise<any>;
  removeInterview: (id: string) => Promise<any>;
  addInterviewDate: (id: string, data: any) => Promise<any>;
  updateInterviewDate: (id: string, data: any) => Promise<any>;
  removeInterviewDate: (id: string, data: any) => Promise<any>;
  setUserInterviewDateValidation: (id: string, validation: any) => Promise<any>;
  setParticipantInterviewDateValidation: (
    id: string,
    validation: any
  ) => Promise<any>;
  isRefetchingSurveys: boolean;
  surveys: Array<any>;
  addSurvey: (data: any) => Promise<any>;
  getSurvey: (id: string) => Promise<any>;
  refetchSurveys: () => Promise<any>;
  updateSurvey: (id: string, form: any) => Promise<any>;
  removeSurvey: (id: string) => Promise<any>;
  addSurveyQuestion: (id: string, data: any) => Promise<any>;
  updateSurveyQuestion: (id: string, data: any) => Promise<any>;
  removeSurveyQuestion: (id: string, data: any) => Promise<any>;
};

export const useAdminDataStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoadingAll: false,
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingAll = true;
          })
        );
        const res = await getAllData();
        const data = res?.data;
        set(
          produce((state: ContextProps) => {
            state.missions = data?.missions ?? [];
            state.countries = data?.countries ?? [];
            state.mdvieOffices = data?.mdvieOffices ?? [];
            state.departments = data?.departments ?? [];
            state.churchs = data?.churchs ?? [];
            state.missionTypes = data?.missionTypes ?? [];
            state.isLoadingAll = false;
          })
        );
        return data;
      },
      isRefetchingStats: false,
      stats: {},
      refetchStats: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingStats = true;
          })
        );
        const res = await getAllStat();
        set(
          produce((state: ContextProps) => {
            state.stats = res?.data ?? {};
            state.isRefetchingStats = false;
          })
        );
        return res;
      },
      isRefetchingDepartments: false,
      departments: [],
      addDepartment: async (form: any) => {
        const res = await addDepartment(form);
        set(
          produce((state: ContextProps) => {
            state.departments = res?.data ?? [];
          })
        );
        return res;
      },
      refetchDepartments: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingDepartments = true;
          })
        );
        const res = await getAllDepartment();
        set(
          produce((state: ContextProps) => {
            state.departments = res?.data ?? [];
            state.isRefetchingDepartments = false;
          })
        );
        return res;
      },
      updateDepartment: async (id: string, form: any) => {
        const res = await updateDepartment(id, form);
        set(
          produce((state: ContextProps) => {
            state.departments = res?.data ?? [];
          })
        );
        return res;
      },
      removeDepartment: async (id: string) => {
        const res = await removeDepartment(id);
        set(
          produce((state: ContextProps) => {
            state.departments = res?.data ?? [];
          })
        );
        return res;
      },
      isRefetchingCountries: false,
      countries: [],
      addCountry: async (name: string) => {
        const res = await addCountry(name);
        set(
          produce((state: ContextProps) => {
            state.countries = res?.data ?? [];
          })
        );
        return res;
      },
      refetchCountries: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingCountries = true;
          })
        );
        const res = await getAllCountry();
        set(
          produce((state: ContextProps) => {
            state.countries = res?.data ?? [];
            state.isRefetchingCountries = false;
          })
        );
        return res;
      },
      updateCountry: async (id: string, form: any) => {
        const res = await updateCountry(id, form);
        set(
          produce((state: ContextProps) => {
            state.countries = res?.data ?? [];
          })
        );
        return res;
      },
      isRefetchingChurchs: false,
      churchs: [],
      addChurch: async (form: any) => {
        const res = await addChurch(form);
        set(
          produce((state: ContextProps) => {
            state.churchs = res?.data ?? [];
          })
        );
        return res;
      },
      refetchChurchs: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingChurchs = true;
          })
        );
        const res = await getAllChurch();
        set(
          produce((state: ContextProps) => {
            state.churchs = res?.data ?? [];
            state.isRefetchingChurchs = false;
          })
        );
        return res;
      },
      updateChurch: async (id: string, form: any) => {
        const res = await updateChurch(id, form);
        set(
          produce((state: ContextProps) => {
            state.churchs = res?.data ?? [];
          })
        );
        return res;
      },
      isRefetchingMissions: false,
      missions: [],
      addMission: async (data: any) => {
        const res = await addMission(data);
        set(
          produce((state: ContextProps) => {
            state.missions = res?.data ?? [];
          })
        );
        return res;
      },
      removeMission: async (id: string) => {
        const res = await removeMission(id);
        set(
          produce((state: ContextProps) => {
            state.missions = res?.data ?? [];
          })
        );
        return res;
      },
      getMission: async (id: string) => {
        const res = await getMission(id);
        return res;
      },
      getMissionStats: async (id: string) => {
        const res = await getMissionStats(id);
        return res;
      },
      updateMission: async (id: string, form: any) => {
        const res = await updateMission(id, form);
        set(
          produce((state: ContextProps) => {
            state.missions = res?.data ?? [];
          })
        );
        return res;
      },
      refetchMissions: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingMissions = true;
          })
        );
        const res = await getAllMission();
        set(
          produce((state: ContextProps) => {
            state.missions = res?.data ?? [];
            state.isRefetchingMissions = false;
          })
        );
        return res;
      },
      addLocality: async (data: any) => {
        const res = await addLocality(data);
        return res;
      },
      refetchLocalities: async (missionId) => {
        const res = await getLocalities(missionId);
        return res;
      },
      getLocality: async (id: string) => {
        const res = await getLocality(id);
        return res;
      },
      updateLocality: async (id: string, form: any) => {
        const res = await updateLocality(id, form);
        return res;
      },
      removeLocality: async (id: string) => {
        const res = await removeLocality(id);
        return res;
      },
      addLocalityTeamMember: async (data: any) => {
        const res = await addLocalityTeamMember(data);
        return res;
      },
      refetchLocalityTeamMembers: async (missionId) => {
        const res = await getLocalityTeamMembers(missionId);
        return res;
      },
      getLocalityTeamMember: async (id: string) => {
        const res = await getLocalityTeamMember(id);
        return res;
      },
      updateLocalityTeamMember: async (id: string, form: any) => {
        const res = await updateLocalityTeamMember(id, form);
        return res;
      },
      removeLocalityTeamMember: async (id: string) => {
        const res = await removeLocalityTeamMember(id);
        return res;
      },
      addMissionary: async (data: any) => {
        const res = await addMissionary(data);
        return res;
      },
      refetchMissionaries: async (missionId) => {
        const res = await getMissionaries(missionId);
        return res;
      },
      getMissionary: async (id: string) => {
        const res = await getMissionary(id);
        return res;
      },
      updateMissionary: async (id: string, form: any) => {
        const res = await updateMissionary(id, form);
        return res;
      },
      removeMissionary: async (id: string) => {
        const res = await removeMissionary(id);
        return res;
      },
      isRefetchingMissionTypes: false,
      missionTypes: [],
      addMissionType: async (data: any) => {
        const res = await addMissionType(data);
        set(
          produce((state: ContextProps) => {
            state.missionTypes = res?.data ?? [];
          })
        );
        return res;
      },
      refetchMissionTypes: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingMissionTypes = true;
          })
        );
        const res = await getAllMissionType();
        set(
          produce((state: ContextProps) => {
            state.missionTypes = res?.data ?? [];
            state.isRefetchingMissionTypes = false;
          })
        );
        return res;
      },
      isRefetchingMdvieOffices: false,
      mdvieOffices: [],
      addMdvieOffice: async (data: any) => {
        const res = await addMdvieOffice(data);
        set(
          produce((state: ContextProps) => {
            state.mdvieOffices = res?.data ?? [];
          })
        );
        return res;
      },
      refetchMdvieOffices: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingMdvieOffices = true;
          })
        );
        const res = await getAllMdvieOffice();
        set(
          produce((state: ContextProps) => {
            state.mdvieOffices = res?.data ?? [];
            state.isRefetchingMdvieOffices = false;
          })
        );
        return res;
      },
      updateMdvieOffice: async (id: string, form: any) => {
        const res = await updateMdvieOffice(id, form);
        set(
          produce((state: ContextProps) => {
            state.mdvieOffices = res?.data ?? [];
          })
        );
        return res;
      },
      isRefetchingUsers: false,
      users: [],
      refetchUsers: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingUsers = true;
          })
        );
        const res = await getAllUser();
        set(
          produce((state: ContextProps) => {
            state.users = res?.data ?? [];
            state.isRefetchingUsers = false;
          })
        );
        return res;
      },
      updateUser: async (id: string, form: any) => {
        const res = await updateUser(id, form);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.users = state.users
                .filter((u) => u.id !== res.data?.id)
                .concat(res?.data ?? []);
            })
          );
        }
        return res;
      },
      updateUserReinitPassword: async (id: string) => {
        const res = await updateUserReinitPassword(id);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.users = state.users
                .filter((u) => u.id !== res.data?.id)
                .concat(res?.data ?? []);
            })
          );
        }
        return res;
      },
      sendUserReinitAccessLink: async (id: string) => {
        const res = await sendUserReinitAccessLink(id);
        if (res.success) {
        }
        return res;
      },
      saveUserReinitAccess: async (id: string, form: any) => {
        const res = await saveUserReinitAccess(id, form);
        return res;
      },
      addUser: async (form: any) => {
        const res = await addUser(form);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.users = state.users
                .filter((u) => u.id !== res.data?.id)
                .concat(res?.data ?? []);
            })
          );
        }
        return res;
      },
      removeUser: async (id: string) => {
        const res = await removeUser(id);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.users = state.users.filter((u) => u.id !== res.data?.id);
            })
          );
        }
        return res;
      },
      isRefetchingInterviews: false,
      interviews: [],
      addInterview: async (data: any) => {
        const res = await addInterview(data);
        set(
          produce((state: ContextProps) => {
            state.interviews = res?.data ?? [];
          })
        );
        return res;
      },
      getInterview: async (id: string) => {
        const res = await getInterview(id);
        return res;
      },
      getInterviewDate: async (id: string) => {
        const res = await getInterviewDate(id);
        return res;
      },
      refetchInterviews: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingInterviews = true;
          })
        );
        const res = await getAllInterview();
        set(
          produce((state: ContextProps) => {
            state.interviews = res?.data ?? [];
            state.isRefetchingInterviews = false;
          })
        );
        return res;
      },
      updateInterview: async (id: string, form: any) => {
        const res = await updateInterview(id, form);
        set(
          produce((state: ContextProps) => {
            state.interviews = res?.data ?? [];
          })
        );
        return res;
      },
      removeInterview: async (id: string) => {
        const res = await removeInterview(id);
        set(
          produce((state: ContextProps) => {
            state.interviews = res?.data ?? [];
          })
        );
        return res;
      },
      addInterviewDate: async (id: string, data: any) => {
        const res = await addInterviewDate(id, data);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.interviews = state.interviews
                .filter((i) => i.id !== id)
                .concat(res.data);
            })
          );
        }
        return res;
      },
      updateInterviewDate: async (id: string, form: any) => {
        const res = await updateInterviewDate(id, form);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.interviews = state.interviews
                .filter((i) => i.id !== id)
                .concat(res.data);
            })
          );
        }
        return res;
      },
      removeInterviewDate: async (id: string, form: any) => {
        const res = await removeInterviewDate(id, form);
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.interviews = state.interviews
                .filter((i) => i.id !== id)
                .concat(res.data);
            })
          );
        }
        return res;
      },
      setUserInterviewDateValidation: async (id, validation) => {
        const res = await setUserInterviewDateValidation(id, validation);
        return res;
      },
      setParticipantInterviewDateValidation: async (id, validation) => {
        const res = await setParticipantInterviewDateValidation(id, validation);
        return res;
      },
      isRefetchingSurveys: false,
      surveys: [],
      addSurvey: async (data: any) => {
        const res = await addSurvey(data);
        set(
          produce((state: ContextProps) => {
            state.surveys = res?.data ?? [];
          })
        );
        return res;
      },
      getSurvey: async (id: string) => {
        const res = await getSurvey(id);
        return res;
      },
      refetchSurveys: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchingSurveys = true;
          })
        );
        const res = await getAllSurvey();
        set(
          produce((state: ContextProps) => {
            state.surveys = res?.data ?? [];
            state.isRefetchingSurveys = false;
          })
        );
        return res;
      },
      updateSurvey: async (id: string, form: any) => {
        const res = await updateSurvey(id, form);
        set(
          produce((state: ContextProps) => {
            state.surveys = res?.data ?? [];
          })
        );
        return res;
      },
      removeSurvey: async (id: string) => {
        const res = await removeSurvey(id);
        set(
          produce((state: ContextProps) => {
            state.surveys = res?.data ?? [];
          })
        );
        return res;
      },
      addSurveyQuestion: async (id: string, data: any) => {
        const res = await addSurveyQuestion(id, data);
        return res;
      },
      updateSurveyQuestion: async (id: string, form: any) => {
        const res = await updateSurveyQuestion(id, form);
        return res;
      },
      removeSurveyQuestion: async (id: string, form: any) => {
        const res = await removeSurveyQuestion(id, form);
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ads")),
    }
  )
);
