import React from "react";
import ImageBg from "../../assets/backgroundImage.jpg";
import { isEmail, isPassword } from "../../constants";
import EmailInputForm from "../../components/form_components/email_input";
import FormMessage from "../../components/form_components/message";
import SubmitForm from "../../components/form_components/submit";
import DialogModal from "../../components/modals/models/dialog";
import PasswordInputForm from "../../components/form_components/password_input";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type Props = {
  user: any;
  isLoading: boolean;
  mutate: UseMutateFunction<AxiosResponse<any, any>, Error, any, unknown>;
};

const UpdateUserAccessForm = ({ user, isLoading, mutate }: Props) => {
  const [messageDialog, setMessageDialog] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    email: null,
    new: null,
    confirm: null,
    general: null,
  });
  function closeDialog() {
    setOpenDialog(false);
    setMessageDialog("");
  }
  function checkForm(form: any) {
    let errorOccured = false;
    // email
    if (form.email.length === 0 || !isEmail(form.email)) {
      errorOccured = true;
      let msg = "Email incorrecte";
      setErrors((e) => ({ ...e, email: msg }));
    } else {
      setErrors((e) => ({ ...e, email: null }));
    }
    // new
    if (form.new.length === 0 || !isPassword(form.new)) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrors((e) => ({ ...e, new: msg }));
    } else {
      setErrors((e) => ({ ...e, new: null }));
    }
    // confirm
    if (form.confirm.length === 0 || !isPassword(form.confirm)) {
      errorOccured = true;
      let msg = "Incorrecte";
      setErrors((e) => ({ ...e, confirm: msg }));
    } else {
      setErrors((e) => ({ ...e, confirm: null }));
    }
    if (
      isPassword(form.new) &&
      isPassword(form.confirm) &&
      form.new !== form.confirm
    ) {
      errorOccured = true;
      let msg = "Confirmation du mot de passe incorrecte";
      setErrors((e) => ({ ...e, general: msg }));
    } else {
      setErrors((e) => ({ ...e, general: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onSubmitForm(e: any) {
    e.preventDefault();
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    const form = e.target.elements;
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      email: form.email.value,
      new: form.new.value,
      confirm: form.confirm.value,
    };
    if (checkForm(formValues)) {
      return;
    }
    const formDatas = {
      email: form.email.value,
      password: form.new.value,
    };
    mutate(formDatas, {
      onError: (err, variable, context) => {
        setErrors((e) => ({
          ...e,
          general: err.message.toString(),
        }));
        // console.log(err, variable, context);
      },
    });
  }
  return (
    <React.Fragment>
      <div className="flex w-full flex-row px-4 gap-[50px] min_pc_mini:px-[10px]">
        <div className="hidden min_pc_mini:flex flex-1 py-10 justify-center">
          <div className="flex w-full phone:w-[100%] rounded-[8px] max-h-[600px] items-center justify-center bg-[#e2e8f0]">
            <img
              src={ImageBg}
              alt="campagne"
              className="flex w-full h-full my-auto select-none mx-auto object-contain rounded-[8px]"
            />
          </div>
        </div>
        <div className="flex flex-1 h-auto flex-col min_pc_mini:py-10 py-10 phone:pt-5 phone:pb-16">
          <div className="flex flex-col min_pc_mini:pt-7 gap-y-4 w-[80%] min_pc_mini:w-[100%] h-auto phone:w-[100%]">
            <div className="flex min_pc_mini:hidden flex-1 justify-center pb-0"></div>
            <span className="flex text-[22px] font-bold select-none">
              Plateforme d’enregistrement des missionnaires
            </span>
            <span className="flex text-[18px] font-semibold select-none">
              Bienvenue {user.firstname + " " + user.lastname}
            </span>
            <span className="flex text-[16px] font-semibold select-none">
              Mettez à jour vos paramètres de connexion.
            </span>
            <form
              className="flex flex-col gap-4"
              onSubmit={!isLoading ? onSubmitForm : undefined}
              onChange={updateCheckForm}
            >
              <EmailInputForm
                defaultValue={user.email}
                title={"Corriger votre adresse email"}
                error={!!errors.email || !!errors.general}
              />
              <span className="flex text-[15px] text-primary">
                Votre nouveau mot de passe doit comporter au minimum 10
                caracteres et doit contenir au moins une lettre minuscule, une
                lettre majuscule et un chiffre.
              </span>
              <PasswordInputForm
                name={"new"}
                error={!!errors.new || !!errors.general}
                title={"Entrer votre nouveau mot de passe"}
              />
              <PasswordInputForm
                name={"confirm"}
                error={!!errors.confirm || !!errors.general}
                title={"Confirmer le mot de passe"}
              />
              {errors.general && (
                <div className="mt-2">
                  <FormMessage
                    message={errors.general}
                    styleMessage={"error"}
                  />
                </div>
              )}
              <SubmitForm isLoading={isLoading} title={"Mettre à jour"} />
            </form>
          </div>
        </div>
      </div>
      <DialogModal
        message={messageDialog}
        handleClose={closeDialog}
        open={openDialog}
      />
    </React.Fragment>
  );
};

export default UpdateUserAccessForm;
