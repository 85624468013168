import React, { useState } from "react";
import ScreenHeader from "../../../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../../../components/screen_content_layout";
import TextInputForm from "../../../../../../../components/form_components/text_input";
import TextAreaInputForm from "../../../../../../../components/form_components/textarea_input";
import SubmitForm from "../../../../../../../components/form_components/submit";
import request from "../../../../../../../configs/request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import FormMessage from "../../../../../../../components/form_components/message";
import { toast } from "sonner";

const DashManageFormationsScreenAdd = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [url, setUrl] = useState("");
  const [errors, setErrors] = React.useState<{
    [x: string]: any;
  }>({
    general: null,
  });
  const { mutate, isPending } = useMutation<any>({
    mutationFn: (data) => {
      return request({
        url: `admin/formation/add`,
        data,
        method: "post",
      });
    },
    onSuccess: async (resData) => {
      queryClient.refetchQueries({
        queryKey: ["admins", "formations"],
      });
      toast("Enregistrement réussie");
      navigate("..", { relative: "path" });
      setErrors({
        general: null,
      });
    },
    onError(error, variables, context) {
      setErrors({
        general: null,
      });
      toast(error.message);
    },
  });
  function checkForm(form: any) {
    let errorOccured = false;
    // title
    if (form.title.length < 2) {
      errorOccured = true;
      let msg = "Titre incorrecte";
      setErrors((e) => ({ ...e, title: msg }));
    } else {
      setErrors((e) => ({ ...e, title: null }));
    }
    // description
    if (form.description.length < 2) {
      errorOccured = true;
      let msg = "Description incorrecte";
      setErrors((e) => ({ ...e, description: msg }));
    } else {
      setErrors((e) => ({ ...e, description: null }));
    }
    // url
    if (form.url.length < 2) {
      errorOccured = true;
      let msg = "Lien incorrecte";
      setErrors((e) => ({ ...e, url: msg }));
    } else {
      setErrors((e) => ({ ...e, url: null }));
    }
    return errorOccured;
  }
  function updateCheckForm(e: any) {
    e.preventDefault();
    const formInput = e.target;
    if (!formInput) {
      return;
    }
    if (!!errors.general) {
      setErrors((e) => ({ ...e, general: null }));
    }
    setErrors((e) => ({ ...e, [formInput.name]: null }));
  }
  function onChangeUrl(e: string) {
    setUrl(e);
  }
  const validateURL = (url: string) => {
    return /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w.@?^=%&:/~+#-]*)?$/i.test(
      url
    );
  };

  function onSubmitForm(e: any) {
    e.preventDefault();
    const form = e.target.elements;
    if (errors.general !== null) {
      setErrors((e) => ({ ...e, general: null }));
    }
    if (form === null) {
      setErrors((e) => ({ ...e, general: "Renseigner le formulaire !" }));
      return;
    }
    const formValues = {
      title: form.title.value,
      description: form.description.value,
      url: form.url.value,
    };
    if (checkForm(formValues)) {
      console.log("Error Occured !!!");
      return;
    }
    const formDatas = {
      title: form.title.value,
      description: form.description.value,
      url: form.url.value,
    };
    setErrors((e) => ({
      ...e,
      general: "",
    }));
    mutate(formDatas as any);
  }
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Ajouter une formation" isBackable></ScreenHeader>
        <ScreenContentLayout>
          <form
            className="flex flex-col gap-4 max-w-[400px]"
            onSubmit={onSubmitForm}
            onChange={updateCheckForm}
          >
            <TextInputForm
              title={"Titre"}
              name={"title"}
              error={!!errors.title}
            />
            <TextAreaInputForm
              title={"Description"}
              name={"description"}
              error={!!errors.description}
            />
            <TextInputForm
              title={"Lien de la vidéo"}
              name={"url"}
              onChange={onChangeUrl}
              error={!!errors.url}
            />
            {url ? (
              validateURL(url) ? (
                <div className="flex items-center justify-center h-[250px] aspect-video bg-black rounded-[8px] relative">
                  <iframe
                    className="flex h-full w-full"
                    src={url}
                    title="Video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                </div>
              ) : (
                <div className="flex text-red-500">
                  <span>Lien invalide</span>
                </div>
              )
            ) : null}
            <span className="flex text-primary">
              Si la vidéo de la formation ne s’affiche pas sous le champ de
              l’URL, vérifiez que le lien utilisé est bien un lien de partage.
            </span>
            {errors.general && (
              <FormMessage message={errors.general} styleMessage={"error"} />
            )}
            <SubmitForm isLoading={isPending} title="Enregistrer" />
          </form>
        </ScreenContentLayout>
      </div>
    </React.Fragment>
  );
};

export default DashManageFormationsScreenAdd;
