import { UserRole } from "../../../configs/stores/admindataStore";

type sidebarListItemProps = {
  path: string;
  title: string;
  authorizedRoles: Array<string>;
  refusedRoles: Array<string>;
  authorizedGrades: Array<string>;
  refusedGrades: Array<string>;
};

export function sidebarListItemFilter(role: any, grade: any) {
  if (!role || typeof role !== "string") {
    return (e: any) => false;
  }
  if (role === UserRole.SUPERADMIN) {
    return (e: any) => true;
  }
  return (item: sidebarListItemProps) => {
    if (
      item.authorizedRoles.includes(role) ||
      item.authorizedGrades.includes(grade)
    ) {
      return true;
    }
    return false;
  };
}

export const sidebarContentList: Array<sidebarListItemProps> = [
  {
    path: "stats",
    title: "Tableau de bord",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "missionaries",
    title: "Missionnaires",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "notifications",
    title: "Notifications",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "interviews",
    title: "Entretiens",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "missions",
    title: "Missions",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "departments",
    title: "Départements",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "manage-formations",
    title: "Formations",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "mdvieoffices",
    title: "Représentations",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "churchs",
    title: "Églises",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
  {
    path: "countries",
    title: "Pays",
    authorizedRoles: [UserRole.ADMIN],
    refusedRoles: [],
    authorizedGrades: [],
    refusedGrades: [],
  },
];
