import React from "react";
import "moment/locale/fr";
import { isAndroid, primaryColor } from "../../constants";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

const styleBox = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid transparent",
  radiusBorder: 8,
  boxShadow: 1,
};

type Props = {
  title: string;
  name: string;
  error?: boolean;
  defaultValue?: string;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  onChange?: (value: { value: string; label: string } | any) => void;
};

const DateInputFrom = ({
  title,
  name,
  defaultValue,
  minDate,
  maxDate,
  error = false,
  onChange,
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [date, setDate] = React.useState<moment.Moment | null>(
    !!defaultValue ? moment(defaultValue) : null
  );
  const [openModal, setOpenModal] = React.useState(false);
  function onSensitiveClick() {
    setOpenModal(true);
  }
  function clearDt() {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = "";
  }
  function update(e: moment.Moment) {
    if (!e) {
      return;
    }
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = e.format("LL");
    !!onChange && onChange(e.format("LL"));
    setDate(moment(e));
  }
  React.useEffect(() => {
    if (date) {
      // const dt = moment(date).valueOf();
      // const now = moment(moment().format("YYYY-MM-DD")).valueOf();
      // if (now > dt) {
      //   clearDt();
      // } else {
      //   if (!inputRef.current) {
      //     return;
      //   }
      //   inputRef.current.value = moment(date).format("LL");
      //   !!onChange && onChange(moment(date).format("LL"));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <React.Fragment>
        <div className="flex flex-col w-full gap-2">
          <span className={`flex text-[15px] ${error ? "text-red-500" : ""}`}>
            {title}
          </span>
          <input
            ref={inputRef}
            type="text"
            name={name}
            defaultValue={
              !!defaultValue ? moment(defaultValue).format("LL") : undefined
            }
            placeholder={title}
            onClick={onSensitiveClick}
            readOnly
            className={` transition-all cursor-pointer placeholder:cursor-text duration-150 flex outline-4 h-[50px] outline-primary px-4 min-w-0 text-[18px] w-full bg-slate-200 rounded-[8px] ${
              error
                ? "placeholder-red-500 border-[3px] border-red-500 outline-transparent outline-none"
                : ""
            }`}
          />
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          slots={{ backdrop: Backdrop }}
          closeAfterTransition
          slotProps={{
            backdrop: {
              style: { backdropFilter: `blur(2px)` },
              timeout: 250,
            },
          }}
        >
          {openModal ? (
            <Fade in={openModal}>
              <Box
                sx={{
                  ...styleBox,
                  minWidth: 200,
                  borderRadius: 2,
                  outline: "none",
                }}
              >
                <div className="flex relative">
                  <div className="flex flex-col gap-1 rounded-[15px] p-3 pt-4 overflow-hidden bg-white">
                    <span className="self-center flex text-[18px] font-medium phone:font-bold">
                      Sélectionner le jour
                    </span>
                    <div className="flex flex-col gap-3">
                      <DateCalendar
                        defaultValue={moment(defaultValue)}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={date}
                        views={["year", "month", "day"]}
                        onChange={update}
                        showDaysOutsideCurrentMonth
                        fixedWeekNumber={6}
                        sx={{
                          color: primaryColor,
                          ...(isAndroid
                            ? {
                                "@media (max-width: 640px)": {
                                  width: "95%",
                                },
                              }
                            : {}),
                        }}
                      />
                      <button
                        onClick={() => setOpenModal(false)}
                        className="flex rounded-[10px] hover:bg-hover phone:hover:bg-primary transition-all duration-150 bg-primary active:bg-active py-3 items-center justify-center"
                      >
                        <span className="flex text-[18px] text-white font-medium">
                          Confirmer
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          ) : (
            <></>
          )}
        </Modal>
      </React.Fragment>
    </LocalizationProvider>
  );
};

export default DateInputFrom;
