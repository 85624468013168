import React from "react";

const MissingPage = ({
  message = "Oops! Page introuvable.",
  children = <div />,
}) => {
  return (
    <div className="flex flex-1 h-full flex-col items-center justify-center">
      <h1 className="flex text-[30px] text-black font-bold leading-tight">
        404
      </h1>
      <h2 className="flex text-[20px] mt-2 font-medium">{message}</h2>
      {children}
    </div>
  );
};

export default MissingPage;

// import React from "react";

// const NotFoundPage = () => {
//   return (
//     <div className="flex flex-col items-center justify-center h-screen text-center px-4">
//       <h1 className="text-6xl font-bold text-red-600">404</h1>
//       <h2 className="text-2xl font-semibold mt-4 text-gray-800">Page non trouvée</h2>
//       <p className="text-lg text-gray-600 mt-2 max-w-md">
//         Désolé, la page que vous recherchez n'existe pas ou a été déplacée.
//       </p>
//       <a href="/" className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700">
//         Retour à l'accueil
//       </a>
//     </div>
//   );
// };

// export default NotFoundPage;
